body {
    background-color: black;
    font-family: Futura, "Trebuchet MS", Arial, sans-serif;
}

h1 {
    font-family: Futura, "Trebuchet MS", Arial, sans-serif;
    letter-spacing: 6px;
}

@media (max-width:768px) {
    h1 {
        font-size: 20px;
    }
  }

  /*html {
    overflow-y: scroll;
  }*/

 .AlbumCover_title__2VrKV {
    font-family: Futura, "Trebuchet MS", Arial, sans-serif;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 26px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    letter-spacing: 8px;
    color: white;
    text-transform: uppercase;
  }

  @media (max-width:768px) {
    .AlbumCover_title__2VrKV {
      font-size: 18px;
      letter-spacing: 4px;
    }
  }
  .AlbumCover_albumCover__2pzE0 {
    display: 'block';
    transition: 'transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s';
    -webkit-filter: brightness(85%);
            filter: brightness(85%);
  }

  .AlbumCover_albumCover__2pzE0:hover {
    -webkit-filter: brightness(35%);
            filter: brightness(35%);
    text-decoration: none;
    text-shadow: 0 1px 0 black;
  }

  .AlbumCover_albumCover__2pzE0:hover + .AlbumCover_title__2VrKV 
  { 
    color: #e0e0e0;
    text-transform: uppercase;
    display:block;
  }
.home_footer__B99U4 {
    text-align: center;
    color: #e0e0e0;
    padding-top: 30px;
    padding-bottom: 30px;
    letter-spacing: 4px;
    font-size: 11px;
}

h1 {
    color: #a8a8a8;
}
