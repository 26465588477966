body {
    background-color: black;
    font-family: Futura, "Trebuchet MS", Arial, sans-serif;
}

h1 {
    font-family: Futura, "Trebuchet MS", Arial, sans-serif;
    letter-spacing: 6px;
}

@media (max-width:768px) {
    h1 {
        font-size: 20px;
    }
  }

  /*html {
    overflow-y: scroll;
  }*/
