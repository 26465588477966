 .title {
    font-family: Futura, "Trebuchet MS", Arial, sans-serif;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 26px;
    transform: translate(-50%, -50%);
    letter-spacing: 8px;
    color: white;
    text-transform: uppercase;
  }

  @media (max-width:768px) {
    .title {
      font-size: 18px;
      letter-spacing: 4px;
    }
  }
  .albumCover {
    display: 'block';
    transition: 'transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s';
    filter: brightness(85%);
  }

  .albumCover:hover {
    filter: brightness(35%);
    text-decoration: none;
    text-shadow: 0 1px 0 black;
  }

  .albumCover:hover + .title 
  { 
    color: #e0e0e0;
    text-transform: uppercase;
    display:block;
  }