.footer {
    text-align: center;
    color: #e0e0e0;
    padding-top: 30px;
    padding-bottom: 30px;
    letter-spacing: 4px;
    font-size: 11px;
}

h1 {
    color: #a8a8a8;
}